<template>
    <div class="transaDetails">
        <div class="navbar">
            <div class="navbar_btn" @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow"></svg-icon>
            </div>
            <div class="navbar_title">交易明细</div>
            <div class="rightBtn"></div>
        </div>
        <div class="listBox">
            <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
                :refreshing="refreshing" :isNoData="isNoData" :isHigehtMax="true">
                <div class="detailItem" v-for="(item, index) in transList" :key="index">
                    <div class="detailItem_top">
                        <div class="title">{{item && item.desc}}</div>
                        <div class="detailSta">{{handleOrderSta(item.status)}}</div>
                        <div class="amount">{{`${handleAmount(item.mark, item.coinAmount)}`}}</div>
                    </div>
                    <div class="detailItem_bottom">
                        <div class="timer">{{item.createdAt | time}}</div>
                        <div>{{(item.recharge * 100 + item.income * 100) / 100}}</div>
                    </div>
                </div>
            </Pullrefresh>
        </div>
    </div>
</template>

<script>
    import {
        queryAppTransDetails
    } from "@/api/mine/wallet.js";
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                pageNum: 1,
                pageSize: 10,
                transList: [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.transList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                }
                let ret = await this.$Api(queryAppTransDetails, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.list;
                    if(list != null ) {
                      this.transList = this.transList.concat(list);
                    }
                    if (list == null || ( list.length == 0 && this.pageNum == 1)) {
                        this.isNoData = true;
                        this.finished = true;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
            },
            // 订单状态
            handleOrderSta(status) {
                switch (status) {
                    case 1:
                        return "成功";
                        // break;
                    case 0:
                        return "审核中";
                        // break;
                    default:
                        break;
                }
            },
            // 金额
            handleAmount(mark, amount) {
                if (mark == 1) {
                    return "+" + amount;
                } else if (mark == 0) {
                    return "-" + amount;
                }
            },

        }
    }
</script>

<style lang="scss" scoped>
    .transaDetails {
        // background: $whiteThree;

        // height: 100vh;

        .listBox {
            height: calc(100vh - 44px);
            padding: 0 10px;
            box-sizing: border-box;
            // background: #000;
        }

        .navbar {
            // position: fixed;
            // top: 0;
            background: $vermillion;
            width: 100%;
            color: $white1;
            height: 44px;
            font-size: 20px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .navbar_title {
                font-weight: 600;
            }

            .rightBtn {
                width: 25px;
            }
        }

        .detailItem {
            height: 70px;
            padding: 12px 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-bottom: 1px solid $silver;

            .detailItem_top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    width: 190px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 12px;
                    color: #1c1a1a;
                }

                .detailSta {
                    width: 40px;
                    height: 18px;
                    line-height: 18px;
                    border-radius: 6px;
                    color: $white1;
                    background: $vermillion;
                    text-align: center;
                }

                .amount {
                    font-size: 12px;
                    color: $vermillion;
                    width: 50px;
                    text-align: right;
                }
            }

            .detailItem_bottom {
                display: flex;
                justify-content: space-between;
                color: $warmGreyTwo;
                font-size: 12px;
            }
        }

    }
</style>
